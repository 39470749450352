<template>
  <div class="divBox">
    <form-panel
      ref="formPanel"
      v-bind="submitConfig"
      :form="form"
      :submitBefore="submitBefore"
      :submitSuccess="submitSuccess"
      @update="update"
    >
      <el-form-item
        label="照片"
        :rules="[{ required: true, message: '请上传照片', trigger: 'blur' }]"
        prop="picture"
      >
        <v-upload :limit="1" :imgUrls.sync="form.picture" />
      </el-form-item>
      <el-form-item
        label="姓名"
        :rules="[{ required: true, message: '请输入姓名', trigger: 'blur' }]"
        prop="name"
      >
        <v-input
          placeholder="请输入姓名"
          v-model="form.name"
          :width="250"
          :maxlength="50"
        ></v-input>
      </el-form-item>
      <el-form-item
        label="手机号"
        :rules="[
          { required: true, message: '请输入手机号', trigger: 'change' },
          { validator: validatePhone, trigger: 'change' },
        ]"
        prop="mobile"
      >
        <div class="form-item">
          <v-input
            placeholder="请输入手机号"
            v-model="form.mobile"
            :disabled="isEdit"
            @blur="handelInputBlur"
          ></v-input>
          <v-button
            v-if="form.id"
            text="编辑"
            style="margin-left: 10px"
            @click="handelClick"
          ></v-button>
        </div>
      </el-form-item>
      <el-form-item
        label="排序"
        :rules="[{ required: true, message: '请输入排序值', trigger: 'blur' }]"
        prop="sort"
      >
        <v-input
          placeholder="请输入排序值"
          v-model="form.sort"
          :width="250"
          type="number"
        ></v-input>
      </el-form-item>

      <el-form-item
        label="标签"
        :rules="[{ required: true, message: '请选择标签', trigger: 'blur' }]"
        prop="label"
      >
        <v-select :options="teacherSignList" v-model="form.label" />
      </el-form-item>
      <el-form-item
        label="微信二维码名片"
        :rules="[
          { required: true, message: '请上传微信二维码名片', trigger: 'blur' },
        ]"
        prop="wechatQrcode"
      >
        <v-upload :limit="1" :imgUrls.sync="form.wechatQrcode" />
      </el-form-item>

      <el-form-item
        label="服务次数"
        :rules="[
          { required: true, message: '请输入服务次数', trigger: 'blur' },
        ]"
        prop="serveCount"
      >
        <v-input
          placeholder="请输入服务次数"
          v-model="form.serveCount"
          :width="250"
          type="number"
        ></v-input>
      </el-form-item>
    </form-panel>
  </div>
</template>

<script>
import { setAESCbcEncrypt } from "@/utils/common";
import { mobileRegular } from "@/utils/regular";
import {
  addTeacherURL,
  getTeacherDetailURL,
  getTeacherInfoURL,
  advisorEdit,
} from "./api";

export default {
  name: "goodsEdit",
  data() {
    return {
      submitConfig: {
        queryUrl: getTeacherDetailURL,
        submitUrl: addTeacherURL,
      },
      teacherSignList: [],
      form: {
        id: "",
        picture: "",
        name: "",
        mobile: "",
        sort: 0,
        label: "",
        wechatQrcode: "",
        serveCount: 0,
      },
      isEdit: false,
    };
  },
  mounted() {
    this.getTeacherInfo();

    const { id, communityId } = this.$route.query;
    this.form.id = id;
    if (id !== undefined) {
      this.isEdit = true;
      this.$refs.formPanel.getData({ id });
    } else {
    }
    this.$setBreadList(id ? "编辑" : "新增");
  },
  methods: {
    handelInputBlur() {
      if (this.form.id) {
        const params = {
          id: this.form.id,
          mobile: setAESCbcEncrypt(this.form.mobile),
        };
        this.$axios.post(advisorEdit, params).then((res) => {
          if (res.code == 200) {
            this.isEdit = true;
            this.$message.success(res.msg);
          }
        });
      }
    },
    handelClick() {
      this.form.mobile = "";
      this.isEdit = false;
    },
    submitSuccess(data) {
      return true;
    },

    submitBefore() {
      if (this.form.id) {
        delete this.form.mobile;
      } else {
        this.form.mobile = setAESCbcEncrypt(this.form.mobile);
      }
      return true;
    },
    getTeacherInfo() {
      let params = {};
      this.teacherSignList = [];
      this.$axios.get(`${getTeacherInfoURL}`, { params }).then((res) => {
        if (res.code === 200) {
          if (res.data && res.data.businessAdvisorLabelList) {
            res.data.businessAdvisorLabelList.forEach((item) => {
              item.label = item.dictValue;
              item.value = item.dictKey;
              this.teacherSignList.push(item);
            });
          }
        }
      });
    },
    validatePhone(rule, value, callback) {
      if (!this.form.id) {
        let regExp = mobileRegular;
        if (!regExp.test(value)) {
          callback(new Error("您填写手机号码格式不正确"));
        } else {
          callback();
        }
      }
    },
    update(data) {
      Object.keys(this.form).forEach((key) => {
        this.form[key] = data[key];
      });
    },
  },
};
</script>
<style lang="less" scoped>
.divBox {
  box-sizing: border-box;
  height: 100%;
  .form-item {
    display: flex;
  }
}
</style>
<style lang="less">
.divBox {
}
</style>
